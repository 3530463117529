import React, { useRef, useEffect } from "react"
import { LongFormPopUpContainer, CloseButton, Section } from "./style"
import { Icon } from "utils/getIcon"
import useOutsideAlerter from "../../../hooks/useOutsiderAlerter"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
const LongFormPopUpModule = ({
  isPopUpOpen,
  setPopUpOpen,
  international,
  content,
  getHelp,
}) => {
  const popUpRef = useRef(null)

  useOutsideAlerter(popUpRef, () => {
    if (isPopUpOpen) {
      setPopUpOpen(false)
    }
  })

  useEffect(() => {
    const targetElement = popUpRef.current
    console.log("targetElement:", targetElement)

    if (isPopUpOpen) {
      disableBodyScroll(targetElement)
    } else {
      enableBodyScroll(targetElement)
    }

    console.log("popUpRef:", popUpRef)
  }, [isPopUpOpen])

  return (
    <LongFormPopUpContainer getHelp={getHelp} ref={popUpRef}>
      <CloseButton
        onClick={() => {
          setPopUpOpen(false)
        }}
      >
        <Icon name="x" />
      </CloseButton>
      {content && <Section dangerouslySetInnerHTML={{ __html: content }} />}

      {international ? (
        <Section>
          <h3>Your rights in Australia after sexual assault</h3>
          <p>
            Many international students worry that if they report or disclose a
            sexual assault or unwanted sexual experience, something will happen
            to their visa. This is not true. There is{" "}
            <span>no impact on your visa status if you choose to report.</span>
          </p>

          <p>
            Sometimes, a partner might use your visa status as a way of
            controlling or threatening you. You might be worried you will be
            sent back home if you leave them. This is not true. You can apply to
            stay in Australia on your own. The Department of Immigration
            provides some information about visas and partner violence{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/partner-onshore/family-violence-and-your-visa"
            >
              here.
            </a>
          </p>

          <p>
            The Department of Social Services also has a fact sheet{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.dss.gov.au/women/publications-articles/reducing-violence/family-violence-and-partner-visas"
            >
              here.
            </a>
          </p>

          <p>
            Some students worry about their family at home finding out that they
            have been assaulted. It is your choice about whether to disclose to
            your family.{" "}
            <span>
              Police and health services will not inform your family unless you
              want them to.
            </span>
          </p>
        </Section>
      ) : null}
    </LongFormPopUpContainer>
  )
}

export default LongFormPopUpModule
