import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { Button } from "components/Button"
import { TextWrapper } from "../reusableComponents"
import { NextButton } from "components/Pagination/style"
import { IconWrapper } from "components/Button/style"

import { Grid } from "components/Grid"
export const BottomGrid = styled(Grid)`
  padding-top: ${props => props.ipv && "20px"};
  border-top: ${props =>
    props.ipv && "1px solid rgba(255, 255, 255, 0.3)"};

  @media ${device.desktop} {
    padding-top: ${props => props.ipv && "40px"};
  }
`

export const ActionPlanTextWrapper = styled(TextWrapper)`
  text-align: center;
  max-width: 760px;
  margin: 0 auto;
  margin-bottom: 30px;

  @media ${device.desktop} {
    margin-bottom: 50px;
  }

  @media ${device.large} {
    margin-bottom: 85px;
  }

  svg {
    margin-left: 9px;
    margin-right: 9px;
    transform: translateY(10px);
    width: 24px;

    @media ${device.desktop} {
      width: 28px;
    }
  }
`
export const TopText = styled(TextWrapper)`
  margin: 0 auto;

  h2 {
    font-size: 32px;
    margin-bottom: 17px;
    line-height: 116%;
    @media ${device.desktop} {
      font-size: 36px;
    }
  }

  h3 {
    font-size: 24px;
    margin-bottom: 17px;
    @media ${device.desktop} {
      font-size: 28px;
    }
  }

  p {
    font-size: 18px;
    @media ${device.desktop} {
      font-size: 20px;
      padding-right: 20px;
    }
  }
  svg {
    margin-left: 9px;
    margin-right: 9px;
    transform: translateY(10px);
    width: 24px;

    @media ${device.desktop} {
      width: 28px;
    }
  }
`

export const ThanksSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 40px;
  grid-gap: 35px;
  @media ${device.desktop} {
    grid-template-columns: 2fr 1fr;
    align-items: flex-start;
    margin-bottom: 50px;
  }

  @media ${device.large} {
    margin-bottom: 85px;
    grid-gap: 70px;
  }
`

export const FeedbackBubble = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  max-width: 370px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 24px;
  @media ${device.desktop} {
    padding: 30px;
    padding-top: 40px;
  }

  h3 {
    font-size: 20px;
    color: #007eae;
    margin-bottom: 17px;

    @media ${device.desktop} {
      font-size: 22px;
    }

    @media ${device.large} {
      font-size: 24px;
    }
  }

  p {
    font-size: 16px;
    color: #4a5a80;
    margin-bottom: 20px;

    @media ${device.desktop} {
      font-size: 18px;
    }
    @media ${device.large} {
      font-size: 20px;
      margin-bottom: 25px;
    }
  }
`
export const FeedbackButton = styled(Button)`
  background: #007eae;
  font-family: "Azo Sans", "Arial", "sans-serif";
  font-weight: 500;
  height: 48px;
  @media ${device.desktop} {
    font-size: 18px;
    height: 62px;
  }

  :hover {
    background: #067098;
  }
`

export const DropDownContainer = styled.div``

export const GridWrapper = styled.div`
  display: ${props => (props.isOpen ? "block" : "none")};
  margin-bottom: 20px;
  @media ${device.desktop} {
    margin-bottom: 40px;
  }
`
export const ActionButton = styled(Button)`
  font-family: "Azo Sans", "Arial", "sans-serif";
  font-size: 16px;
  color: white;
  background: #3b10b1;
  border-radius: 5px;
  font-weight: 500;
  height: 60px;
  margin-bottom: 20px;
  background: #3b10b1;
  position: relative;
  padding: 0 55px;
  text-align: center;
  @media ${device.desktop} {
    margin-bottom: 40px;
    font-size: 24px;
    height: 80px;
  }

  ${IconWrapper} {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    border: 2px solid white;
    /* margin-left: auto; */
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    @media ${device.desktop} {
      right: 30px;
    }

    svg {
      height: 7px;
      transition: 200ms;
      transform: ${props =>
        props.isOpen ? "rotate(90deg) scale(1)" : "rotate(90deg) scale(-1)"};
      g,
      path {
        fill: white;
      }
    }
  }

  :hover {
    background: #2c0695;
  }
`

export const ActionPlanMain = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.mainMaxWidth};
`

export const ActionCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: relative;

  p {
    font-size: 16px;
    color: #4a5a80;
    padding: 15px 20px 10px 20px;
    flex-grow: 1;

    @media ${device.desktop} {
      font-size: 20px;
      padding: 25px 30px 20px 30px;
    }
  }
`
export const ViewButton = styled(NextButton)`
  width: 100%;
  border-top: 1px solid #eaeaea;
  line-height: 12px;
  font-size: 12px;
  padding: 15px 20px;
  justify-content: flex-start;

  ${IconWrapper} {
    margin: 0;
    margin-right: 13px;
    height: 24px;
    width: 24px;
    svg {
      width: 8px;
    }
  }

  @media ${device.desktop} {
    height: 77px;
    padding: 25px 30px;
  }

  :hover {
    opacity: 0.7;
    text-decoration: none;
  }
`

export const WarningBox = styled(ActionCardContainer)`
  background: #fefff0;
  padding: 25px 35px 20px 60px;

  @media ${device.desktop} {
    padding: 40px 50px 40px 70px;
  }
  h3 {
    font-size: 20px;
    margin-bottom: 14px;

    color: #aa1e78;
    @media ${device.desktop} {
      margin-bottom: 15px;
      font-size: 24px;
    }
  }

  p {
    padding: 0;
  }

  svg {
    width: 22px;
    position: absolute;
    left: 22px;
    top: 19px;

    path {
      fill: #ffce4e;
    }
    @media ${device.desktop} {
      width: 27px;
      top: 34px;
      left: 27px;
    }
  }
`
