import styled from "styled-components"
import { device } from "utils/breakpoints.js"

export const PageContentContainer = styled.div`
  position: relative;
  display: flex;
  height: auto;
  flex-direction: column;
  /* min-height: calc(100vh - 80px); */
  @media ${device.desktop} {
    flex-direction: column;
    /* min-height: calc(100vh - 100px); */
  }

  > div.transitionGroup {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-self: flex-start;
    margin: ${props => props.cover && "0 auto"};
    position: ${props => (props.cover ? "fixed" : "relative")};
  }
`

export const ScreenPosition = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  pointer-events: ${props => props.disabled && "none"};
  /* min-height: calc(100vh - 230px); */
  /* min-height: 1000px; */

  > div {
    width: 100%;
  }
`
