import React, { useState } from "react"
import {
  EmailFormContainer,
  CustomForm,
  SendButton,
  EmailInput,
  SuccessMessage,
} from "./style"
import { useMutation } from "@apollo/react-hooks"
import gql from "graphql-tag"

const SEND_ACTION_PLAN_EMAIL = gql`
  mutation SendActionPlanEmail($link: String!, $email: String!) {
    sendActionPlanEmail(
      input: {
        clientMutationId: "Email Form Submission"
        email: $email
        link: $link
      }
    ) {
      data
      success
    }
  }
`

const EmailForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const [sendActionPlanEmail] = useMutation(SEND_ACTION_PLAN_EMAIL)

  const onSubmit = values => {
    setSubmitted(true)

    sendActionPlanEmail({
      variables: {
        email: values.email,
        link: document.location.href,
      },
    })
  }

  return (
    <EmailFormContainer>
      <h3>Get a copy of the action plan strategies via email</h3>
      {!submitted && (
        <CustomForm onSubmit={onSubmit} noValidate>
          <EmailInput
            type="email"
            name="email"
            placeholder="Your email address…"
            error="Please enter a valid email."
          />
          <SendButton submit label="Send" />
        </CustomForm>
      )}
      {submitted && (
        <SuccessMessage>
          Thank you, your results will be delivered to your email shortly.
        </SuccessMessage>
      )}

      {!submitted && (
        <p>We won’t collect your email or use it for anything else.</p>
      )}
    </EmailFormContainer>
  )
}

export default EmailForm
