import styled from "styled-components"
import { device } from "utils/breakpoints.js"

export const LongFormPopUpContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: ${props => `calc(100% - 2*${props.theme.mobileGutter})`};
  background: #ffffff;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  transition: 400ms;
  z-index: 2;
  padding: 0 20px;
  margin-bottom: 20px;

  max-width: 785px;
  max-width: ${props => props.getHelp && "425px"};

  @media ${device.desktop} {
    top: 40px;
    padding: 0 40px;
    margin-bottom: 40px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 33px;
  width: 33px;
  border-radius: 50%;
  border: 2px solid white;
  background: #007eae;
  transform: translate3d(50%, -50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    height: 11px;
  }

  @media ${device.desktop} {
    transform: translate3d(97%, 0%, 0);
    right: -12px;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eaeaea;
  padding-top: 20px;
  padding-bottom: 10px;

  @media ${device.desktop} {
    padding-top: 40px;
    padding-bottom: 16px;
  }
  a {
    color: #4a5a80;
    font-weight: 600;
    word-break: break-word;

    :hover {
      text-decoration: underline;
    }
  }
  h3 {
    color: #007eae;
    font-size: 22px;
    margin-bottom: 10px;
    @media ${device.desktop} {
      font-size: 28px;
      margin-bottom: 15px;
    }
  }

  p,
  > div {
    color: #4a5a80;
    font-size: 16px;
    margin-bottom: 20px;

    @media ${device.desktop} {
      font-size: 20px;
      margin-bottom: 30px;
    }

    span {
      font-weight: 600;
    }
  }

  ul {
    margin-left: 24px;
    margin-bottom: 26px;

    @media ${device.desktop} {
      margin-bottom: 30px;
    }
  }

  div.getHelp {
    h3 {
      font-size: 16px;
      color: #c3015c;
      font-weight: 600;
      margin-bottom: 28px;
      font-family: "Adelle", "Times New Roman", "serif";
      text-transform: none;
      text-align: left;
      line-height: 150%;
      margin-top: 10px;
    }
    ul {
      margin-bottom: 0;
      margin-top: 20px;
      a {
        font-weight: 400;
      }

      li {
        font-size: 16px !important;
      }
      li:last-of-type {
        margin-bottom: 0;
      }

      span {
        font-weight: 600;
      }
    }

    a {
      position: relative;
      display: inline-block;
      margin-left: 27px;

      :before {
        content: "";
        position: absolute;
        top: 50%;
        top: 50%;
        left: -5px;
        transform: translate3D(-100%, -50%, 0);
        width: 20px;
        height: 1px;
        background: #4a5a80;
      }
    }
  }

  li {
    list-style-type: none;
    position: relative;
    font-size: 16px;
    color: #4a5a80;

    margin-bottom: 20px;
    @media ${device.desktop} {
      font-size: 20px;
    }

    ::before {
      content: "";
      height: 8px;
      width: 8px;
      background: #aaa1d5;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 6px;
      left: -22px;

      @media ${device.large} {
        top: 8px;
      }
    }

    span {
      font-weight: 600;
    }
  }
`
