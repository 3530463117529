import styled from "styled-components"
import { device } from "../../utils/breakpoints.js"
import { Button } from "../Button/index.js"
import { IconWrapper } from "../Button/style.js"
import { RangeRatherNotSay, ConfirmBtn } from "components/Cards/Card/style"
import { CloseButton } from "components/PopUp/LongFormPopUpModule/style"

export const PopUpContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: ${props => (props.isPopUpOpen ? "block" : "none")};
  transition: 80ms;
  pointer-events: ${props => (props.isPopUpOpen ? "all" : "none")};
  overflow: auto;
`
export const FeedbackCloseButton = styled(CloseButton)`
  @media ${device.desktop} {
    right: auto;
    transform: none !important;
    left: -50px;
  }
`

export const PopUpBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: black;
  opacity: 0.8;
  z-index: -1;

  @media ${device.desktop} {
    @media (min-height: 750px) {
      height: 100%;
    }
  }
`

export const ClosePopUpButton = styled(Button)`
  font-size: 10px;
  color: #175f7a;
  letter-spacing: 0.83px;
  justify-content: flex-end;
  height: 80px;
  width: 100%;
  padding-right: 20px;
  font-family: "Azo Sans", "Arial", "sans-serif";
  @media ${device.desktop} {
    @media (min-height: 750px) {
      font-size: 12px;
      height: 99px;
      padding-right: 40px;
      padding-right: ${props => props.shove && "57px"};
    }
  }

  ${IconWrapper} {
    border-radius: 50%;
    background: #007eae;
    height: 40px;
    width: 40px;
    margin-left: 15px;

    svg {
      height: 18px;
      transform: scale(-1);
      g,
      path {
        fill: white;
      }
    }
    @media ${device.desktop} {
      @media (min-height: 750px) {
        height: 48px;
        width: 48px;
      }
    }
  }

  :hover {
    svg {
      opacity: 0.8;
    }
    text-decoration: underline;
  }
`

export const PopUpMain = styled.div`
  max-width: 420px;
  position: relative;
  width: 90vw;
  margin: 0 auto;
  z-index: 2;
  background: white;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: left;
  padding-top: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  @media ${device.desktop} {
    @media (min-height: 750px) {
      max-width: ${props => (props.tall ? "580px" : "420px")};
      position: absolute;
      margin-top: 0px;
      margin-bottom: 0px;
      bottom: 0;
      right: 0;
      padding-top: 60px;
      /* height: 760px; */
    }
  }
`

export const TitleTextContainer = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #d4d2d2;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  flex-grow: 1;

  @media ${device.desktop} {
    @media (min-height: 750px) {
      padding-bottom: 125px;
      padding-left: 60px;
      padding-right: 70px;
      padding-bottom: ${props => props.tall && "80px"};
    }
  }

  h2 {
    font-size: 20px;
    color: #007eae;
    letter-spacing: -0.12px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 12px;
    @media ${device.desktop} {
      @media (min-height: 750px) {
        font-size: 28px;
        letter-spacing: -0.17px;
        line-height: 36px;
        margin-bottom: 25px;
      }
    }
  }

  p {
    color: #4a5a80;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 20px;

    @media ${device.desktop} {
      @media (min-height: 750px) {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 30px;
      }
    }

    :last-of-type {
      margin-bottom: 0;
    }

    span {
      font-weight: 600;
    }

    a {
      font-weight: 600;
      color: inherit;
      :hover {
        text-decoration: underline;
      }
    }
  }
`
export const Title = styled.h1`
  font-size: 20px;
  color: #007eae;
  letter-spacing: -0.12px;
  line-height: 24px;
  text-align: left;
  @media ${device.desktop} {
    @media (min-height: 750px) {
      font-size: 28px;
      letter-spacing: -0.17px;
      line-height: 36px;
    }
  }
`
export const Text = styled.p`
  color: #4a5a80;
  letter-spacing: 0;
  line-height: 24px;
  @media ${device.desktop} {
    @media (min-height: 750px) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  span {
    font-weight: 600;
  }
`

export const FeelingInput = styled.textarea`
  background: #ffffff;
  border: 1px solid #007eae;
  box-shadow: 0 3px 0 0 #007eae;
  border-radius: 4px;
  width: 100%;
  word-wrap: break-word;
  min-height: 173px;
  resize: none;
  outline: none;
  padding: 15px;
  font-size: 16px;
  color: #6b7783;
  opacity: ${props => (props.submitted ? 0 : 1)};
  pointer-events: ${props => props.submitted && "none"};
  @media ${device.desktop} {
    @media (min-height: 750px) {
      font-size: 20px;
      height: 236px;
    }
  }

  ::placeholder {
    color: #6b7783;
    opacity: 1;
  }
`
export const FeelingTitle = styled(Title)`
  font-size: 28px;
  color: #007eae;
  letter-spacing: -0.17px;
  line-height: 36px;
`
export const FeelingSubTitle = styled.h4`
  font-size: 16px;
  font-family: "Adelle", "Times New Roman", "serif";
  color: #4a5a80;
  line-height: 140%;
  opacity: ${props => (props.submitted ? 0 : 1)};
  pointer-events: ${props => props.submitted && "none"};
  @media ${device.desktop} {
    @media (min-height: 750px) {
      font-size: 20px;
    }
  }
`
export const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  opacity: ${props => (props.submitted ? 0 : 1)};
  pointer-events: ${props => props.submitted && "none"};
  margin-top: 15px;
`
export const NotAnswer = styled(RangeRatherNotSay)`
  margin-right: 0;
  width: 100% !important;
  height: 46px !important;
  font-size: 12px;
  padding: 0 13px;
`
export const Submit = styled(ConfirmBtn)`
  width: 100%;
  height: 46px;
  font-size: 14px;
  width: 100% !important;

  @media ${device.desktop} {
    height: 62px;
  }
`
export const Skip = styled(ClosePopUpButton)`
  color: ${props => props.theme.teal};
  text-transform: uppercase;
  font-size: 10px;
  pointer-events: ${props => (props.disabled ? "none" : "all")};
  opacity: ${props => (props.disabled ? "0.3" : "1")};
  transition: opacity 120ms;

  ${IconWrapper} {
    border: 2px solid ${props => props.theme.blue};
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background: none;
    transform: scale(-1);
    margin-left: 15px;
    bottom: 30px;

    svg {
      height: 18px;
      g,
      path {
        fill: ${props => props.theme.blue};
      }
    }

    @media ${device.desktop} {
      @media (min-height: 750px) {
        height: 48px;
        width: 48px;
      }
    }
  }
`

export const FeelingTitleTextContainer = styled(TitleTextContainer)`
  @media ${device.desktop} {
    @media (min-height: 750px) {
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 240px;
    }
  }
`
export const QuestionnairePopUpMain = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`
export const QuestionnaireExitPopUpContainer = styled.div`
  margin: 85px auto;
  background: #ffffff;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 26px;
  max-width: 371px;
  width: 90%;
  position: relative;

  @media (min-width: 420px) {
    margin-top: 95px;
    margin-left: calc(100% - 392px);
  }
  @media (min-width: 1340px) {
    margin-left: calc(97% - 371px);
  }
`

export const QuestionnairePopUpContainer = styled(
  QuestionnaireExitPopUpContainer
)`
  margin: 85px auto;

  @media (min-width: 420px) {
    margin: 85px auto;
  }
  @media (min-width: 1340px) {
    margin: 85px auto;
  }
`
export const QuestionnairePopUpText = styled.p`
  font-size: 16px;
  color: #4a5a80;
  letter-spacing: 0;
  text-align: center;
  line-height: 24px;
`
export const QuestionnairePopUpExit = styled(QuestionnairePopUpText)`
  text-align: left;
  span {
    display: block;
    font-weight: 700;
    color: #c3015c;
    margin-bottom: 30px;
  }
`
export const IconArrow = styled.div`
  position: absolute;
  top: -35px;
  left: 47%;

  > svg {
    width: 68px;
    height: 59px;
  }
`
