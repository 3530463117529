import React, { useState } from "react"
import PropTypes from "prop-types"
import { Icon } from "utils/getIcon"
import {
  PopUpMain,
  Title,
  FeelingInput,
  Submit,
  Buttons,
  FeelingSubTitle,
  FeelingTitleTextContainer,
  FeedbackCloseButton,
} from "./style"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"

const CREATE_FEEDBACK = gql`
  mutation CreateFeedbackResponse($response: String!) {
    createFeedbackResponse(
      input: { clientMutationId: "CreateFeedbackResponse", response: $response }
    ) {
      data
      success
    }
  }
`

const FeelingsPopUpModule = ({
  title,
  subtitle,
  text,
  buttonRight,
  setPopUpOpen,
}) => {
  const [feelingsResponse, setFeelingsResponse] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [createFeedbackResponse] = useMutation(CREATE_FEEDBACK)

  const submitResponse = () => {
    setSubmitted(true)
    console.log(feelingsResponse)

    createFeedbackResponse({
      variables: {
        response: feelingsResponse,
      },
    })
  }

  return (
    <PopUpMain>
      <FeedbackCloseButton
        onClick={() => {
          setPopUpOpen(false)
        }}
      >
        <Icon name="x" />
      </FeedbackCloseButton>
      <FeelingTitleTextContainer>
        <Title style={{ marginBottom: "20px" }}>
          {submitted ? "Thank you for your feedback!" : title}
        </Title>
        <FeelingSubTitle style={{ marginBottom: "20px" }} submitted={submitted}>
          {subtitle}
        </FeelingSubTitle>
        <FeelingInput
          submitted={submitted}
          placeholder={text}
          value={feelingsResponse}
          onChange={e => setFeelingsResponse(e.target.value)}
        />
        <Buttons submitted={submitted}>
          <Submit label={buttonRight} onClick={() => submitResponse()} />
        </Buttons>
      </FeelingTitleTextContainer>
    </PopUpMain>
  )
}

export default FeelingsPopUpModule

FeelingsPopUpModule.defaultProps = {
  title: "Tell us what you think",
  subtitle: "Please enter your feedback below:",
  text: ["Your feedback…"],
  buttonLeft: "I’d rather not answer.",
  buttonRight: "SUBMIT",
  button: "SKIP",
}

FeelingsPopUpModule.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.array,
  buttonLeft: PropTypes.string,
  buttonRight: PropTypes.string,
  button: PropTypes.string,
}
