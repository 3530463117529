import React, { useContext, useEffect } from "react"
import { PopUpContext } from "context/PopUpContext"
import { FlowContext } from "context/FlowContext"
import FeedbackPopUpModule from "./FeedbackPopUpModule"
import FeelingsPopUpModule from "./FeelingsPopUpModule"
import { LongFormPopUpModule } from "./LongFormPopUpModule"
import QuestionnairePopUpModule from "./QuestionnairePopUpModule"
import { VideoModal } from "components/VideoModal"

import { PopUpContainer, PopUpBackground } from "./style"

const PopUp = () => {
  const { currentScreen, nextMove } = useContext(FlowContext)
  const {
    isPopUpOpen,
    popUpType,
    videoUrl,
    setPopUpOpen,
    message,
  } = useContext(PopUpContext)

  const escHandler = event => {
    console.log('hello')

    if (event.keyCode === 27 && isPopUpOpen) {
      setPopUpOpen(false)

    }
  }

  useEffect(() => {
    if (
      popUpType === "video" ||
      popUpType === "longForm" ||
      popUpType === "feelings" ||
      popUpType === "international" ||
      popUpType === "getHelp"
  
    ) {
      document.addEventListener("keydown", escHandler)
      return function cleanup() {
        window.removeEventListener("keydown", escHandler)
      }
    }
  })

  const handleClick = () => {
    if (popUpType === "questionnaire") {
      setPopUpOpen(false)
    }
  }

  return (
    <PopUpContainer
      isPopUpOpen={isPopUpOpen}
      onClick={() => {
        handleClick()
      }}
    >
      <PopUpBackground />
      {popUpType === "feelings" ? (
        <FeelingsPopUpModule setPopUpOpen={setPopUpOpen} nextMove={nextMove} />
      ) : popUpType === "feedback" ? (
        <FeedbackPopUpModule
          setPopUpOpen={setPopUpOpen}
          nextMove={nextMove}
          message={message}
        />
      ) : popUpType === "video" ? (
        <VideoModal
          isPopUpOpen={isPopUpOpen}
          setPopUpOpen={setPopUpOpen}
          url={videoUrl}
        />
      ) : popUpType === "questionnaire" ? (
        <QuestionnairePopUpModule setPopUpOpen={setPopUpOpen} />
      ) : popUpType === "longForm" ? (
        <LongFormPopUpModule
          setPopUpOpen={setPopUpOpen}
          content={message}
          isPopUpOpen={isPopUpOpen}
        />
      ) : popUpType === "getHelp" ? (
        <LongFormPopUpModule
          getHelp
          setPopUpOpen={setPopUpOpen}
          content={message}
          isPopUpOpen={isPopUpOpen}
        />
      ) : popUpType === "international" ? (
        <LongFormPopUpModule international setPopUpOpen={setPopUpOpen} isPopUpOpen={isPopUpOpen}/>
      ) : null}
    </PopUpContainer>
  )
}

export default PopUp
