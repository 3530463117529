import styled from "styled-components"
import { device } from "../../utils/breakpoints.js"

export const ProgressBarContainer = styled.div`
  display: inline-flex;

  flex-direction: column;
  align-self: flex-end;
  margin-bottom: 5px;
  padding-right: 20px;
  transition: opacity 300ms;
  transition-delay: 600ms;
  opacity: ${props => (props.hide ? 0 : 1)};
  visibility: ${props => (props.hide ? "hidden" : "visible")};

  @media ${device.desktop} {
    flex-direction: row;
    align-items: center;
    align-self: center;
    margin-bottom: 0;
  }
`

export const ProgressWrapper = styled.div`
  width: 70px;
  height: 6px;
  border-radius: 3px;
  background: rgba(59, 16, 177, 0.2);
  display: flex;
  align-items: center;
  padding: 0 2px;

  @media (min-width: 350px) {
    width: 90px;
  }
  @media ${device.desktop} {
    width: 150px;
  }
`

export const Range = styled.div`
  background: ${props => props.theme.green};
  border-radius: 3px;
  transition: width 850ms ease-in;
  height: 2px;
`
export const PercentDisplay = styled.h5`
  color: ${props => props.theme.offWhite};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  transition: 180ms;
  opacity: ${props => (props.zero ? 0.3 : 1)};

  @media ${device.desktop} {
    width: 45px;
    text-align: right;
    margin-right: 18px;
    margin-bottom: 0;
  }
`
