const size = {
  mobile: "480px",
  tablet: "768px",
  desktop: "1024px",
  large: "1280px",
  big: "1600px",
}


export const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,
  large: `(min-width: ${size.large})`,
  big: `(min-width: ${size.big})`,
}