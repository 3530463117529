import styled from 'styled-components';
import { device } from '../../utils/breakpoints.js';

export const AvenueGrid = styled.div`
	display: grid;
	grid-template-columns: ${(props) => (props.cols ? `repeat(${props.cols[0]},1fr)` : null)};
	grid-template-rows: ${(props) => (props.rows ? `repeat(${props.rows[0]},1fr)` : null)};
	grid-auto-flow: ${(props) => props.flow || null};
	grid-gap: ${(props) => props.gap ? `${props.gap[0]}px` : props.theme.mobileGap};

	@media ${device.tablet} {
		grid-gap: ${(props) => props.gap ? `${props.gap[1]}px` : props.theme.tabletGap};
		grid-template-columns: ${(props) => (props.cols ? `repeat(${props.cols[1]},1fr)` : null)};
		grid-template-rows: ${(props) => (props.rows ? `repeat(${props.rows[1]},1fr)` : null)};
	}

	@media ${device.desktop} {
		grid-gap: ${(props) => props.gap ? `${props.gap[2]}px` : props.theme.largeGap};
		grid-template-columns: ${(props) => (props.cols ? `repeat(${props.cols[2]},1fr)` : null)};
		grid-template-rows: ${(props) => (props.rows ? `repeat(${props.rows[2]},1fr)` : null)};
	}

`;
