import React from "react"
import { ReactComponent as Cross } from "../images/icons/cross.svg"
import { ReactComponent as Arrow } from "../images/icons/single-arrow.svg"
import { ReactComponent as DoubleArrow } from "../images/icons/double-arrow.svg"
import { ReactComponent as PlayIcon } from "../images/icons/play-icon.svg"
import { ReactComponent as X } from "../images/icons/x.svg"
import { ReactComponent as ErrorIcon } from "../images/icons/error.svg"

import { ReactComponent as RoundArrow } from "../images/icons/roundArrow.svg"
import { ReactComponent as TopArrow } from "../images/icons/top-arrow.svg"
import { ReactComponent as Warning } from "../images/icons/warning.svg"

const iconMap = {
  cross: { icon: Cross },
  arrow: { icon: Arrow },
  "double-arrow": { icon: DoubleArrow },
  play: { icon: PlayIcon },
  x: { icon: X },
  "round-arrow": { icon: RoundArrow },
  "top-arrow": { icon: TopArrow },
  error: { icon: ErrorIcon },
  warning: { icon: Warning },
}

export const hasIcon = name => {
  return !!iconMap[name]
}

export const Icon = ({ name, className }) => {
  return iconMap[name]
    ? React.createElement(iconMap[name].icon, { className: className || name })
    : false
}
