import React from "react"
import { AvenueGrid } from "./style"

// const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
const Grid = props => {
  // flow = 'column' || 'row'
  // rows = {[3 b.p]}
  // cols = {[3 b.p]}
  // gap = {[3 b.p]}

  return <AvenueGrid {...props}></AvenueGrid>
}

export default Grid
