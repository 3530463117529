import styled from "styled-components"
// import { device } from "utils/breakpoints.js"
import ReactPlayer from "react-player"

export const VideoModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  padding-top: 56.25%;
  width: ${props => `calc(100% - 2*${props.theme.mobileGutter})`};
  background: rgba(0, 0, 0, 0.9);
  transition: 400ms;
  z-index: 2;

  @media (min-width: 983px) {
    width: 943px;
    height: 530px;
    padding-top: 0;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  border: 2px solid white;
  background: ${props => props.theme.purple3};
  transform: translate3d(50%, -50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    height: 16px;
  }
`

export const ReactVideo = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
`
