import React, { useState } from "react"
import { ActionButton, DropDownContainer, GridWrapper } from "./style"
import ActionCard from "./ActionCard"
import { Grid } from "components/Grid"
import { Icon } from "utils/getIcon"

const DropDown = ({ label, sectionData }) => {
  const [isOpen, setIsOpen] = useState(true)
  const singleArrow = <Icon name="arrow" />

  return (
    <>
      {sectionData && sectionData.length > 0 && (
        <DropDownContainer>
          <ActionButton
            fullWidth
            isOpen={isOpen}
            iconRight
            icon={singleArrow}
            label={label}
            onClick={() => setIsOpen(!isOpen)}
          />

          <GridWrapper isOpen={isOpen}>
            <Grid cols={[1, 2, 3]} gap={[20, 20, 45]}>
              {sectionData.map((section, index) => {
                return (
                  <ActionCard
                    key={index}
                    message={section.title}
                    popUpContent={section.data.content}
                  />
                )
              })}
            </Grid>
          </GridWrapper>
        </DropDownContainer>
      )}
    </>
  )
}

export default DropDown
