import React, { useContext } from "react"
import { PageContainer } from "./style"
import { FlowContext } from "context/FlowContext.js"

const PageWrapper = ({ className, style, children, normalScroll }) => {
  const { currentScreen } = useContext(FlowContext)
  return (
    <PageContainer
      style={style}
      className={className}
      noPagination={currentScreen.data.noPagination}
      normalScroll={normalScroll}
    >
      {children}
    </PageContainer>
  )
}

export default PageWrapper
