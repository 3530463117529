import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { Form } from "components/Form"
import { TextInput } from "../../components/Form/TextInput"
import { GetStartedButton } from "components/CoverScreen/style"
export const EmailFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 25px 35px;
  @media ${device.desktop} {
    padding: 40px 40px;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #007eae;

    @media ${device.desktop} {
      font-size: 24px;
    }
  }

  p {
    font-size: 14px;
    color: #4a5a80;

    @media ${device.desktop} {
      font-size: 16px;
    }
  }
`

export const CustomForm = styled(Form)`
  display: flex;
  flex-direction: column;

  @media (min-width: 500px) {
    flex-direction: row;
  }
`

export const EmailInput = styled(TextInput)`
  margin-bottom: ${props => (props.showError === true ? "40px" : "20px")};
  flex-grow: 1;

  @media (min-width: 500px) {
    margin-right: 20px;
    margin-bottom: 0;
  }
  input {
    border-radius: 5px;
    border: ${props =>
      props.showError === true ? "1px solid #AA1E57" : "1px solid #007eae"};
    border-bottom: ${props =>
      props.showError === true ? "4px solid #AA1E57" : "4 px solid #007eae"};
    height: 50px;
    color: #6b7783;
    padding: 3px 15px 3px 15px;
    @media (min-width: 500px) {
      padding: 5px 20px 5px 20px;

      height: 60px;
      margin-bottom: 0px;
    }

    ::placeholder {
      opacity: 1;
    }
  }
`

export const SendButton = styled(GetStartedButton)`
  background: #007eae;
  text-transform: uppercase;
  height: 50px;
  width: 120px;
  @media (min-width: 500px) {
    height: 62px;
    width: 135px;
  }

  :hover {
    background: #067098;
  }
`

export const SuccessMessage = styled.p`
  font-size: 16px !important;
  color: #4a5a80;

  @media ${device.desktop} {
    font-size: 20px !important;
  }
`
