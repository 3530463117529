import styled from "styled-components"
import { device } from "../../utils/breakpoints.js"

export const PageContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  justify-items: center;
  padding: 50px 20px;
  padding-bottom: ${props => !props.noPagination && "136px"};
  min-height: calc(100vh - 86px);

  @media ${device.desktop} {
    padding: 70px 40px;
    padding-bottom: ${props => !props.noPagination && "170px"};
    min-height: calc(100vh - 130px);
  }

  @media ${device.large} {
    padding: 70px 0;
    padding-bottom: ${props => !props.noPagination && "170px"};
  }

  @media ${device.big} {
    padding: 120px 0;
    padding-bottom: ${props => !props.noPagination && "220px"};
  }
`
