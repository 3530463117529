const primaryColor = "#9E1164"
const white = "#FFFFFF"

const theme = {
  primaryColor: primaryColor,
  linkColor: primaryColor,
  btnColor: primaryColor,

  blue: "#007EAE",
  oceanBlue: "#3B10B1",
  purple: "#4A5A80",
  lightPurple: "#806DB5",
  purple2: "#7549E9",
  purple3: "#5825E4",
  teal: "#175F7A",
  grey: "#848F94",
  lightGrey: "#F5F4F6",
  green: "#23BEA3",
  offWhite: "#FDF6EF",
  bodyMaxWidth: "1180px",
  btnHoverColor: "#84004b",
  btnTextColor: white,
  mobileGutter: "20px",
  desktopGutter: "40px",
  mainMaxWidth: "1200px",

  mobileGap: "15px",
  tabletGap: "20px",
  largeGap: "50px",

  // colors: {
  //   grey: ["#F4F4F4", "#D7E1E2", "#9CACAE", "#4A5050", "#E2E6E9"],
  //   black: "#19191A",
  //   teal: "#24A49E",
  //   turquoise: "#00CDC2",
  //   pink: "#E4316E",
  //   zircon: "#D7E1E2",
  //   SanMarino: "#556CAA",
  //   BrightTurquoise: "#09CADB",
  //   Cardinal: "#CC1933",
  // },
}

export default theme
