import React, { useEffect } from "react"
import { Icon } from "utils/getIcon"

import {
  QuestionnairePopUpMain,
  QuestionnaireExitPopUpContainer,
  QuestionnairePopUpContainer,
  QuestionnairePopUpText,
  QuestionnairePopUpExit,
  IconArrow,
} from "./style"

const QuestionnairePopUpModule = ({ setPopUpOpen }) => {
  const escHandler = event => {
    if (event.keyCode === 27) {
      setPopUpOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", escHandler)
    return function cleanup() {
      window.removeEventListener("keydown", escHandler)
    }
  })

  return (
    <QuestionnairePopUpMain>
      <QuestionnaireExitPopUpContainer>
        <IconArrow>
          <Icon name="top-arrow" />
        </IconArrow>
        <QuestionnairePopUpExit>
          <span>
            To leave this site quickly, click the ‘Quick Exit’ button.
          </span>
          If you feel like you are in danger, please call 000 immediately.
        </QuestionnairePopUpExit>
      </QuestionnaireExitPopUpContainer>

      <QuestionnairePopUpContainer>
        <QuestionnairePopUpText>
          You can use the buttons at the bottom of the screen to proceed, skip
          questions or go back a step.
        </QuestionnairePopUpText>
      </QuestionnairePopUpContainer>
    </QuestionnairePopUpMain>
  )
}

export default QuestionnairePopUpModule
