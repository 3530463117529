import React, { useRef } from "react"
import { VideoModalContainer, CloseButton, ReactVideo } from "./style"
import { Icon } from "../../utils/getIcon"
import useOutsideAlerter from "../../hooks/useOutsiderAlerter"

const VideoModal = ({ isPopUpOpen, setPopUpOpen, url }) => {
  const popUpRef = useRef(null)

  useOutsideAlerter(popUpRef, () => {
    if (isPopUpOpen) {
      setPopUpOpen(false)
    }
  })
  return (
    <VideoModalContainer ref={popUpRef}>
      <CloseButton
        onClick={() => {
          setPopUpOpen(false)
        }}
      >
        <Icon name="x" />
      </CloseButton>
      <ReactVideo
        controls={true}
        url={isPopUpOpen ? url : null}
        playing={isPopUpOpen}
      />
    </VideoModalContainer>
  )
}

export default VideoModal
