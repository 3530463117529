import styled from "styled-components"
import { device } from "utils/breakpoints.js"

export const PageTitle = styled.h2`
  font-size: 32px;
  margin-bottom: 26px;

  @media ${device.tablet} {
    font-size: 38px;
  }
  @media ${device.desktop} {
    margin-bottom: 30px;

    font-size: 48px;
  }
`

export const StandardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 600px;
  margin: 0 auto;
  grid-gap: 35px;
  align-items: center;

  @media ${device.desktop} {
    max-width: ${props => props.theme.bodyMaxWidth};
  align-items: flex-start;

  }

  h2 {
    font-size: 32px;
    margin-bottom: 26px;

    @media ${device.tablet} {
      font-size: 38px;
    }
    @media ${device.desktop} {
      margin-bottom: 30px;

      font-size: 48px;
    }
  }

  @media ${device.desktop} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
  }

  @media ${device.large} {
    grid-gap: 100px;
  }
`
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  h3 {
    font-size: 28px;
    margin-bottom: 25px;
    line-height: 120%;

    @media ${device.desktop} {
      margin-bottom: 30px;
      font-size: 32px;
    }
    @media ${device.large} {
      font-size: 36px;
    }
  }

  p {
    margin-bottom: 26px;
    font-size: 18px;
    span {
      font-weight: 600;

      &.underline {
        text-decoration: underline !important;
      }
    }

    a {
      color: inherit;
      font-weight: 600;
      text-decoration: underline;
    }
    &.margin {
      margin-bottom: 26px !important;
    }

    &.underline {
      text-decoration: underline;
    }

    &.bold {
      font-weight: 600;
    }

    @media ${device.desktop} {
      font-size: 24px;
      margin-bottom: 30px;

      &.margin {
        margin-bottom: 30px !important;
      }
    }
  }

  p.instructions {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
    @media ${device.desktop} {
      font-size: 16px;
      /* margin-top: -10px; */
      /* margin-bottom: 70px; */
    }
  }

  ul {
    margin-left: 24px;
    margin-bottom: 26px;

    @media ${device.desktop} {
      margin-bottom: 30px;
    }
  }

  li {
    list-style-type: none;
    position: relative;
    font-size: 16px;
    margin-bottom: 20px;
    @media ${device.desktop} {
      font-size: 22px;
    }

    ::before {
      content: "";
      height: 8px;
      width: 8px;
      background: white;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 6px;
      left: -22px;

      @media ${device.desktop} {
        top: 10px;
      }
    }
  }

  *:last-child {
    margin-bottom: 0;
  }
`
