import React, { useContext } from "react"
import {
  ProgressBarContainer,
  ProgressWrapper,
  Range,
  PercentDisplay,
} from "./style"
import { FlowContext } from "context/FlowContext.js"

const ProgressBar = ({ cover, actionPlan }) => {
  const { currentScreen, screensLength, currentScreenIndex } = useContext(
    FlowContext
  )

  const noProgress = currentScreen.noProgress
  const progress = (currentScreenIndex / screensLength) * 100
  const number = Math.round(progress)
  return (
    <ProgressBarContainer hide={cover}>
      <PercentDisplay zero={!actionPlan && noProgress}>
        {actionPlan ? 100 : noProgress ? 0 : number}%
      </PercentDisplay>
      <ProgressWrapper>
        <Range
          style={{ width: `${actionPlan ? 100 : noProgress ? 0 : progress}%` }}
        />
      </ProgressWrapper>
    </ProgressBarContainer>
  )
}

export default ProgressBar
