import React from "react"
import { PageContentContainer, ScreenPosition } from "./style"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import "utils/transitions.css"
import { NavBar } from "components/NavBar"
import usePrevious from "../../hooks/usePrevious"

const SelectedScreen = React.memo(
  ({
    children,
    currentScreenIndex,
    screensLength,
    disabled,
    animationDuration,
    actionPlan,
  }) => {
    const previousScreenIndex = usePrevious(currentScreenIndex) || -1
    const forwards = currentScreenIndex > previousScreenIndex

    return (
      <PageContentContainer>
        <NavBar
          screensLength={screensLength}
          currentScreenIndex={currentScreenIndex}
          actionPlan={actionPlan}
        />
        <TransitionGroup
          className="transitionGroup"
          childFactory={child =>
            React.cloneElement(child, {
              classNames: forwards ? "right" : "left",
            })
          }
        >
          <CSSTransition
            key={currentScreenIndex}
            in={true}
            appear={true}
            timeout={animationDuration}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <ScreenPosition disabled={disabled}>
              {children}
            </ScreenPosition>
          </CSSTransition>
        </TransitionGroup>
      </PageContentContainer>
    )
  },

  (prevProps, nextProps) => {
    if (prevProps.currentScreenIndex !== nextProps.currentScreenIndex) {
      return false
    } else {
      return true
    }
  }
)

export default SelectedScreen
