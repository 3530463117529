import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import theme from "../utils/theme.js"
import { ThemeProvider } from "styled-components"
// import { CSSTransition } from "react-transition-group"
import { CoverBackgroundWrapper } from "components/CoverScreen/style"
import DoubledCover from "images/doubled-cover-bg.jpg"

import "../fonts/adelle/stylesheet.css"
import "../fonts/azo-sans/stylesheet.css"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="app-wrapper">
        <main>{children}</main>
        <CoverBackgroundWrapper>
          <img src={DoubledCover} alt="" />
          <img src={DoubledCover} alt="" />
        </CoverBackgroundWrapper>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
