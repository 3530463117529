import styled from "styled-components"
import { device } from "src/utils/breakpoints"
import { Button } from "../Button"

export const CardsContainer = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.desktop} {
    padding-top: ${props =>
      props.yesNoPadding ||
      props.slider ||
      props.numberedScale ||
      props.compositeScale
        ? "50px"
        : 0};
  }
`
export const CardsWrapper = styled.div`
  position: relative;
  /* margin-bottom: 0px; */
`

export const CardsList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${props =>
    props.compositeScale
      ? "450px"
      : props.numberedScale
      ? "526px"
      : props.slider
      ? "336px"
      : props.big
      ? props.highCards
        ? "596px"
        : "466px"
      : props.highCards
      ? "274px"
      : "206px"};
  width: calc(100vw - 40px);
  max-width: 420px;
  position: relative;
  margin: 0 auto;
  margin-bottom: ${props =>
    (props.slider || props.numberedScale || props.compositeScale) && "40px"};
  perspective: 100px;
  perspective-origin: ${props =>
    props.compositeScale
      ? "50% -28%"
      : props.numberedScale
      ? "50% -25%"
      : props.slider
      ? " 50% -35%"
      : props.big
      ? "50% -30%"
      : "50% -60%"};

  @media ${device.tablet} {
    height: ${props =>
      props.compositeScale
        ? "352px"
        : props.numberedScale
        ? "283px"
        : props.slider
        ? "270px"
        : props.big
        ? props.highCards
          ? "435px"
          : "391px"
        : props.highCards
        ? "300px"
        : "250px"};
    max-width: 540px;
    margin-bottom: ${props =>
      (props.slider || props.numberedScale || props.compositeScale) && "25px"};

    perspective-origin: ${props =>
      props.numberedScale
        ? "50% -55%"
        : props.slider
        ? " 50% -55%"
        : props.big
        ? "50% -40%"
        : "50% -60%"};
  }
`

export const ButtonsContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 10;
  display: flex;
  transition: 240ms;
  transition-delay: 100ms;
  opacity: ${props => (props.cardsCompleted ? 0 : 1)};
  pointer-events: ${props => (props.cardsCompleted ? "none" : null)};
`
export const AcceptBtn = styled(Button)`
  width: 64px;
  height: 64px;
  font-size: 16px;
  border-radius: 50%;
  transition: all 0.1s;
  text-transform: uppercase;
  background: ${props => props.theme.blue};
  font-family: "Azo Sans", "Arial", "sans-serif";
  font-weight: 500;
  @media ${device.tablet} {
    width: 73px;
    height: 73px;
    font-size: 20px;
  }

  :hover {
    background: #036f99;
  }
`

export const RejectBtn = styled(AcceptBtn)`
  margin-right: 10px;
  line-height: 100%;
  background: ${props => props.theme.purple2};

  :hover {
    background: #5d35c8;
  }
`

export const RatherNotAnswerBtn = styled(Button)`
  background: #3b10b1;
  box-shadow: 0 10px 25px 0 rgba(21, 87, 96, 0.25);
  border-radius: 5px;
  color: white;
  font-size: 12px;
  width: 142px;
  padding: 0;
  text-align: center;
  font-weight: 500;
  height: 35px;
  margin-top: ${props => (props.noPerspective ? "30px" : "60px")};
  margin-bottom: 30px;

  transition: 240ms;
  transition-delay: 100ms;
  opacity: ${props => (props.cardsCompleted ? 0 : 1)};
  pointer-events: ${props => (props.cardsCompleted ? "none" : null)};

  @media ${device.tablet} {
    font-size: 14px;
    width: 168px;
  }

  :hover {
    background: #26077c;
  }
`

export const DoneModule = styled.div`
  text-align: center;
  transition: 240ms;
  transition-delay: 100ms;
  opacity: ${props => (props.cardsCompleted ? 1 : 0)};
  pointer-events: ${props => (props.cardsCompleted ? null : "none")};
  align-self: center;
  h3 {
    font-size: 22px;
    margin-bottom: 14px;
    @media ${device.desktop} {
      font-size: 28px;
      margin-bottom: 25px;
    }
  }

  p {
    font-size: 16px;
    margin-bottom: 14px;

    @media ${device.desktop} {
      font-size: 20px;
      margin-bottom: 28px;
    }
  }

  img {
    height: 50px;
    width: 50px;

    @media ${device.desktop} {
      height: 75px;
      width: 75px;
    }
  }

  button.continue {
    font-family: inherit;
    color: inherit;
    padding: 0;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }
`
