import styled from "styled-components"
import { device } from "utils/breakpoints"
import { Button } from "components/Button"
import { ButtonsContainer, RatherNotAnswerBtn } from "../style"

export const PrimaryCardText = styled.h4`
  text-align: center;
  font-size: 18px;
  color: ${props => props.theme.purple};
  font-family: "Adelle", "Times New Roman", "serif";
  line-height: 150%;
  margin-bottom: ${props =>
    props.slider || props.numberedScale || props.compositeScale
      ? "25px"
      : null};

  @media ${device.tablet} {
    margin-bottom: ${props =>
      props.slider || props.numberedScale || props.compositeScale
        ? "15px"
        : null};
    font-size: ${props =>
      props.slider || props.numberedScale || props.compositeScale
        ? "20px"
        : "24px"};
  }
`
export const SecondaryCardText = styled(PrimaryCardText)`
  font-size: 16px !important;
  margin-top: 25px;
  margin-bottom: 15px;
  position: relative;

  @media ${device.tablet} {
    margin-top: 45px;
    margin-bottom: 25px;
  }
  ::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0%, 0);
    top: -15px;
    width: calc(100% + 50px);
    height: 1px;
    background: #eaeaea;
    opacity: 1;

    @media ${device.tablet} {
      top: -27px;
      width: calc(100% + 90px);
    }
  }
`

export const List = styled.ul`
  transform: none;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin-left: 40px;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.desktop} {
    margin-left: 30px;
  }

  li {
    position: relative;
    font-size: 16px;
    color: #4a5a80;
    padding-right: 30px;
    margin-bottom: 15px;
    list-style-type: none;
    ::before {
      content: "";
      height: 8px;
      width: 8px;
      background-color: #aaa1d5;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 6px;
      left: -20px;
    }
  }
`

export const Counter = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: ${props => (props.big ? "13px" : "20px")};

  @media ${device.tablet} {
    margin-bottom: 20px;
  }

  h5 {
    display: inline-block;
    font-size: 12px;
    color: ${props => props.theme.grey};

    @media ${device.tablet} {
      font-size: 14px;
    }
  }

  h5.current {
    color: ${props => props.theme.blue};
    margin-right: 4px;
  }

  :after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${props => props.theme.green};
  }
`

export const CardContainer = styled.div`
  display: ${props => (props.hidden ? "none" : "flex")};
  position: absolute;
  left: 50%;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 20px 40px 0px;
  border-radius: 5px;
  background: #fff;
  opacity: 1;
  transition: all 0.6s;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  padding: ${props =>
    (props.slider || props.numberedScale || props.compositeScale) &&
    "30px 25px"};

  @media ${device.tablet} {
    padding: 30px 45px;
    padding: ${props =>
      (props.slider || props.numberedScale || props.compositeScale) &&
      "30px 35px"};
  }

  &:nth-child(3),
  &:nth-child(4),
  &.hide {
    > * {
      display: none;
    }
  }

  &:nth-child(1) {
    z-index: 4;
    transform: translate3d(-50%, 0%, 0px);
  }

  &:nth-child(2) {
    z-index: 3;
    opacity: 0.8;
    transform: translate3d(-50%, 0%, -10px);
  }

  &:nth-child(3) {
    z-index: 2;
    opacity: 0.5;
    transform: translate3d(-50%, 0%, -20px);
  }

  &:nth-child(4) {
    z-index: 1;
    opacity: 0.2;
    transform: translate3d(-50%, 0%, -30px);
  }

  &.yes {
    transform: translate3d(-20%, 200px, 13px) rotate3d(0, 0, 1, -40deg) !important;
    opacity: 0;
    visibility: hidden;
  }

  &.no {
    transform: translate3d(-80%, 200px, 13px) rotate3d(0, 0, 1, 40deg) !important;
    opacity: 0;
    visibility: hidden;
  }

  &.ratherNotAnswer,
  &.skip,
  &.confirm {
    transform: translate3d(-50%, 130px, 0) scale(0.7) !important;
    opacity: 0;
    visibility: hidden;
  }

  &.hide {
    opacity: 0;
    transition: 0.3s;
    transform: translate3d(-50%, 0%, -40px) !important;
    visibility: hidden;
    z-index: -1;
  }

  &.opaque {
    opacity: 1;
  }
`

export const SkipCardBtn = styled(Button)`
  color: ${props => props.theme.teal};
  font-weight: 600;
  font-size: 10px;
  font-family: "Azo Sans", "Arial", "sans-serif";
  position: absolute;
  padding: 0;
  padding-bottom: 1px;
  bottom: 20px;
  right: 20px;
  height: auto;
  line-height: 100%;
  border-radius: 0;
  border-bottom: 1px solid #45afd8;
  opacity: 1;
  transition: 90ms;

  :hover {
    opacity: 0.7;
  }
  @media ${device.tablet} {
    font-size: 12px;
  }

  :before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    /* padding: 5px; */
    width: 250%;
    height: 300%;
  }
`

export const CardTextArea = styled.textarea`
  height: 121px;
  border: 1px solid #007eae;
  box-shadow: 0 3px 0 0 #007eae;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 14px;
  padding: 10px;
  resize: none;
  color: #6b7783;
  font-size: 16px;

  @media ${device.tablet} {
    height: 130px;
    margin-top: 20px;
    font-size: 20px;
    margin-bottom: 30px;
    padding: 20px;
  }
  ::placeholder {
    vertical-align: middle;
    color: #6b7783;
    text-align: center;
    opacity: 1;
  }
`

export const RangeButtonsContainer = styled(ButtonsContainer)`
  position: relative;
  margin: 0 auto;
  margin-top: 24px;
  left: auto;
  transform: none;
  @media ${device.tablet} {
    margin-top: 15px;
  }
`

export const RangeRatherNotSay = styled(RatherNotAnswerBtn)`
  position: static;
  margin: 0;
  height: 36px;
  width: 76px;
  font-family: "Azo Sans", "Arial", "sans-serif";
  margin-right: 15px;
  line-height: 116%;
  font-size: 11px;
  box-shadow: none;
  margin: ${props => props.cas && 0};
  @media (min-width: 360px) {
    margin-right: 15px;
    height: 46px;
    width: 96px;
  }

  @media ${device.tablet} {
    height: 44px;
    width: 120px;
  }
`

export const ConfirmBtn = styled(RangeRatherNotSay)`
  text-transform: uppercase;
  background: #007eae;
  margin: 0;
  font-size: 13px;

  @media (min-width: 360px) {
    margin-right: 0;
    font-size: 14px;
  }

  @media ${device.tablet} {
    font-size: 16px;
  }

  :hover {
    background: #036b92;
  }
`
