import React, { useContext } from "react"
import { ActionCardContainer, ViewButton } from "./style"
import { Icon } from "utils/getIcon"
import { PopUpContext } from "context/PopUpContext.js"

const ActionCard = ({ message, popUpContent }) => {
  const singleArrow = <Icon name="arrow" />
  const { togglePopUp } = useContext(PopUpContext)

  const content = popUpContent
  return (
    <ActionCardContainer>
      <p>{message}</p>

      <ViewButton
        noBackground
        fullWidth
        label="View"
        icon={singleArrow}
        onClick={() => togglePopUp(true, "longForm", null, content)}
      />
    </ActionCardContainer>
  )
}

export default ActionCard
