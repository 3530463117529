import styled from "styled-components"
import { device } from "../../utils/breakpoints.js"
import { Button } from "components/Button"
import { IconWrapper } from "../Button/style.js"

export const PaginationContainer = styled.div`
  display: flex;
  opacity: ${props => (props.hide ? 0 : 1)};
  pointer-events: ${props => (props.hide ? "none" : "all")};

  transition: 400ms;
  transition-delay: 600ms;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  height: ${props => (props.hide ? "0" : "80px")};
  box-shadow: 0 -10px 45px 0 rgba(0, 0, 0, 0.15);
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;

  @media ${device.desktop} {
    height: ${props => (props.hide ? "0" : "100px")};
  }
`

export const PreviousButtonContainer = styled.div`
  height: 100%;
  width: 50%;
  background: ${props => props.theme.lightGrey};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  position: relative;
  cursor: pointer;

  @media ${device.tablet} {
    padding-left: 20px;
  }
  @media ${device.desktop} {
    padding-left: 40px;
  }
`

export const NextButtonContainer = styled(PreviousButtonContainer)`
  background: white;
  justify-content: flex-end;
  padding-right: 15px;

  @media ${device.tablet} {
    padding-right: 20px;
  }
  @media ${device.desktop} {
    padding-right: 40px;
    padding-right: ${props => props.shove && "57px"};
    /* background: rgba(255,255,255,0.7); */
  }
`

export const NextButton = styled(Button)`
  color: ${props => props.theme.teal};
  font-family: "Azo Sans", "Arial", "sans-serif";
  text-transform: uppercase;
  font-size: 10px;
  padding: 0;
  height: auto;
  letter-spacing: 0.6px;
  pointer-events: ${props => (props.disabled ? "none" : "all")};
  opacity: ${props => (props.disabled ? "0.3" : "1")};
  transition: opacity 320ms;

  @media ${device.desktop} {
    font-size: 12px;
  }

  ${IconWrapper} {
    border-radius: 50%;
    background: #007eae;
    height: 40px;
    width: 40px;
    margin-left: 15px;

    svg {
      height: 18px;
      transform: scale(-1);
      g,
      path {
        fill: white;
      }
    }

    @media ${device.desktop} {
      height: 48px;
      width: 48px;
    }
  }

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  :hover {
    text-decoration: underline;
  }
`

export const PreviousButton = styled(NextButton)`
  color: ${props => props.theme.lightPurple};

  ${IconWrapper} {
    border: 2px solid ${props => props.theme.lightPurple};
    background: none;
    margin-left: 0;
    margin-right: 15px;

    svg {
      transform: scale(1);

      g,
      path {
        fill: ${props => props.theme.lightPurple};
      }
    }
  }
`
