import React, { useContext, useState, useEffect } from "react"
import { Icon } from "utils/getIcon"
import PropTypes from "prop-types"
import { FlowContext } from "context/FlowContext.js"
import { PopUpContext } from "context/PopUpContext.js"
import getOS from "helpers/getOS"
import { PopUpMain, TitleTextContainer, ClosePopUpButton } from "./style"
const FeedbackPopUpModule = ({ nextMove, setPopUpOpen, message }) => {
  const singleArrow = <Icon name="arrow" />
  const { currentScreen } = useContext(FlowContext)
  const tall = currentScreen.data.popUp && currentScreen.data.popUp.tall
  const [shove, setShove] = useState(false)

  const operatingSystem = getOS()
  const windows = operatingSystem === "Windows"

  useEffect(() => {
    if (windows) {
      setShove(true)
    } else {
      setShove(false)
    }
  }, [windows])

  useEffect(() => {
    console.log("shove:", shove)
  }, [shove])
  return (
    <PopUpMain tall={tall}>
      <TitleTextContainer tall={tall} dangerouslySetInnerHTML={{ __html: message }} />
      <ClosePopUpButton
      shove={shove}
        label={"CONTINUE"}
        onClick={() => {
          setPopUpOpen(false)
          nextMove()
          window.scrollTo(0, 0)
        }}
        noBackground
        iconRight
        icon={singleArrow}
      />
    </PopUpMain>
  )
}

export default FeedbackPopUpModule
